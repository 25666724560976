import Card from "react-bootstrap/Card";
import "./footer.css";

function Footer() {
  return (
    <div className="app-footer">
      <div style={{ textAlign: "center" }}>
        <Card className="bg-dark text-white">
          <Card.Text>
            Welcome to Roman's Pizza to treat yourself with awesome pizza and
            chicken. We serve fresh and hot.
          </Card.Text>
          <Card.Text>Last prepared! Fresh for you!</Card.Text>
        </Card>
      </div>
    </div>
  );
}

export default Footer;
