export const headerDetails = [
  {
    name: "Home",
    displayName: "Home",
    imageURL: "",
    route: "",
    additionalInfo: "",
  },
  {
    name: "Menu",
    displayName: "Menu",
    imageURL: "",
    route: "",
    additionalInfo: "",
  },
  {
    name: "ContactUs",
    displayName: "Contact Us",
    imageURL: "",
    route: "",
    additionalInfo: "",
  },
  {
    name: "Logo",
    displayName: "Home",
    imageURL: "",
    route: "",
    additionalInfo: "",
  },
  {
    name: "PhoneNumber",
    displayName: "641-919-3351",
    imageURL: "",
    route: "",
    additionalInfo: "",
  },
  {
    name: "OnlineOrder",
    displayName: "Order Online",
    imageURL: "",
    route: "",
    additionalInfo: "We deliver to your home",
  },
];

export const mealTypes = [
  "Pizza",
  "Grinders",
  "Specialty Pizza",
  "Appetizers",
  "Wings",
  "Calzones",
  "Salads",
  "Wraps",
  "Club Sandwiches",
  "Chef's Special",
  "Sandwiches",
  "Hamburgers",
  "Fried Dinners",
  "Italian Dinners",
  "Hot Dogs",
  "Omlets",
  "Kid's Meal",
  "Sodas",
];

export const coupons = [
  {
    couponId: 1,
    couponType: "Discount",
    mealType: "Appetized",
    mealItemTitle: "Hot Wings",
    mealItemDescription:
      "10 Chicken Wings with a side of fries/salad and 1 Soda",
    highlightedItemCount: 1,
    price: 8.99,
  },
  {
    couponId: 2,
    couponType: "",
    mealType: "",
    mealItemTitle: "",
    mealItemDescription: "",
    highlightedItemCount: 1,
    price: 0,
  },
  {
    couponId: 3,
    couponType: "",
    mealType: "",
    mealItemTitle: "",
    mealItemDescription: "",
    highlightedItemCount: 1,
    price: 0,
  },
  {
    couponId: 4,
    couponType: "",
    mealType: "",
    mealItemTitle: "",
    mealItemDescription: "",
    highlightedItemCount: 1,
    price: 0,
  },
  {
    couponId: 5,
    couponType: "",
    mealType: "",
    mealItemTitle: "",
    mealItemDescription: "",
    highlightedItemCount: 1,
    price: 0,
  },
  {
    couponId: 6,
    couponType: "",
    mealType: "",
    mealItemTitle: "",
    mealItemDescription: "",
    highlightedItemCount: 1,
    price: 0,
  },
  {
    couponId: 7,
    couponType: "",
    mealType: "",
    mealItemTitle: "",
    mealItemDescription: "",
    highlightedItemCount: 1,
    price: 0,
  },
];

export const home = {
  imageURL: "/images/pizza/pizza.jpg",
  titleMessage:
    "Taste the differene with our unique pizza and spices. We make it different for each one of you!",
  managerMessage: `We have been operating since past 20 years to bring great pizza to your home. 
    We are grateful to serve our local community. Our pizza making process starts from scratch to give you the freshness and unique taste everytime.`,
};
