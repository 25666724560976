export const contact_us = {
  storeName: "Roman's Pizza",
  address: {
    address1: "100 Lee Drive",
    address2: "",
    city: "Davenport",
    state: "IA",
    zipCode: 52807,
    Phone: ["641.919.3351", "641.919.3351"],
    Fax: ["641.919.3351"],
  },
  socialMediaContact: [
    {
      imageURL: "",
      socialMediaName: "Facebook",
      socialMediaURL: "https://facebook.com",
      isActive: true,
    },
    {
      imageURL: "",
      socialMediaName: "Twitter",
      socialMediaURL: "https://twitter.com",
      isActive: true,
    },
  ],
};
