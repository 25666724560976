import React from "react";
import Pizza from "./pizza";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./menu.css";
import AdditionalMenu from "./additional_menu";
import { chickenCravings, sodas, sides } from "../../data/additional_menu";

class Menu extends React.Component {
  render() {
    return (
      <div className="menu-container">
        <Container fluid>
          <Row></Row>
          <Row>
            <Pizza />
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col sm={12} md={4}>
              <AdditionalMenu data={chickenCravings} />
            </Col>
            <Col sm={12} md={4}>
              <AdditionalMenu data={sides} />
            </Col>
            <Col sm={12} md={4}>
              <AdditionalMenu data={sodas} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Menu;
