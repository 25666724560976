import React from "react";
import "./contact-us.css";
import Card from "react-bootstrap/Card";
import { contact_us } from "../../data/contactus_data";

class ContactUs extends React.Component {
  getContactCard = () => {
    return (
      <Card style={{ width: "18rem" }}>
        <Card.Body>
          <Card.Title>Find Us here</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {contact_us.storeName}
          </Card.Subtitle>
          <Card.Text>{contact_us.address.address1}</Card.Text>
          <Card.Text>
            {contact_us.address.city +
              ", " +
              contact_us.address.state +
              " " +
              contact_us.address.zipCode}
          </Card.Text>
          <Card.Text>
            {contact_us.address.Phone.map((phoneNumber) => {
              return (
                <div>
                  <span className="contact-us-phone-label">Phone:</span>
                  <a href={"tel:" + phoneNumber}>{phoneNumber}</a>
                </div>
              );
            })}
          </Card.Text>
          <Card.Subtitle className="md-2">Stay Connected With Us</Card.Subtitle>
          <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
            <div>
              <Card.Img variant="top" src="/images/icon/facebook.png" />
            </div>
            <div>
              <Card.Img variant="top" src="/images/icon/twitter.png" />
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };
  render() {
    return (
      <div className="contact-us-container">
        <div>{this.getContactCard()}</div>
      </div>
    );
  }
}

export default ContactUs;
