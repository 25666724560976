export const chickenCravings = {
  title: "Chicken Craving",
  menuItems: [
    {
      itemName: "Chicken Bucket",
      size: null,
      price: 9.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 8,
      imageURL: "",
      description:
        "8 pieces of fried Chicken including 2 drums, 2 wings, 2 thighs and 2 breasts",
    },
    {
      itemName: "Jumbo Chicken Wings",
      size: null,
      price: 3.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 4,
      imageURL: "",
      description: "4pc- 3.99 8pc- 6.99 12pc- 9.99 16pc- 12.99 20pc- 15.99 ",
    },
    {
      itemName: "Chicken Nuggets",
      size: null,
      price: 5.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 10,
      imageURL: "",
      description: "10 pcs- 5.99 20pcs- 9.99",
    },
    {
      itemName: "Chicken Tender",
      size: null,
      price: 9.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 8,
      imageURL: "",
      description: "6 pcs- 5.99 10pcs- 9.99",
    },
    {
      itemName: "What A Chicken",
      size: null,
      price: 9.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 8,
      imageURL: "",
      description: "Chef's special Spicy Chicken",
    },
  ],
};

export const sides = {
  title: "Sides",
  menuItems: [
    {
      itemName: "Fish",
      size: null,
      price: 5.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 8,
      imageURL: "",
      description:
        "Batter dipped & fried cat fish. 2pcs- 5.99 4pcs- 7.99 6pcs- 9.99",
    },
    {
      itemName: "Jumbo Chicken Wings",
      size: null,
      price: 3.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 4,
      imageURL: "",
      description: "4pc- 3.99 8pc- 6.99 12pc- 9.99 16pc- 12.99 20pc- 15.99 ",
    },
    {
      itemName: "Chicken Nuggets",
      size: null,
      price: 5.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 10,
      imageURL: "",
      description: "10 pcs- 5.99 20pcs- 9.99",
    },
    {
      itemName: "Chicken Tender",
      size: null,
      price: 9.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 8,
      imageURL: "",
      description: "6 pcs- 5.99 10pcs- 9.99",
    },
    {
      itemName: "What A Chicken",
      size: null,
      price: 9.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 8,
      imageURL: "",
      description: "Chef's special Spicy Chicken",
    },
  ],
};

export const sodas = {
  title: "Drinks",
  menuItems: [
    {
      itemName: "Pepsi",
      size: null,
      price: 1.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 1,
      imageURL: "",
      description: "",
    },
    {
      itemName: "Coke",
      size: null,
      price: 1.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 1,
      imageURL: "",
      description: "",
    },
    {
      itemName: "Sprite",
      size: null,
      price: 1.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 1,
      imageURL: "",
      description: "",
    },
    {
      itemName: "Dr Pepper",
      size: null,
      price: 1.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 1,
      imageURL: "",
      description: "",
    },
    {
      itemName: "Iced Tea",
      size: null,
      price: 1.99,
      unit: "$",
      additionalToppingPrice: null,
      numberOfPieces: 1,
      imageURL: "",
      description: "",
    },
  ],
};
