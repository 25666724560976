const pizzaToppings = [
  "Sausage",
  "Pepperoni",
  "Hamburger",
  "Bacon",
  "Chicken",
  "Mushroom",
  "Olives",
  "Peppers",
  "Onions",
  "Eggplant",
  "Garlic",
  "Tomato",
  "Spinach",
  "Meatball",
  "Ham",
  "Artichoke",
  "Pineapple",
  "Jalapenos",
  "Broccoli",
  "Feta Cheese",
  "Clam",
  "Beef",
];

const pizzaPriceOptions = [
  {
    pizzaName: "Margherita Cheese",
    size: null,
    price: 9.99,
    unit: "$",
    additionalToppingPrice: null,
    numberOfSlices: 8,
    imageURL: "/images/pizza/sample_pizza.png",
    description: "Regular pizza source with full cheesy falvour of mozzarella",
  },
  {
    pizzaName: "Meat Lover",
    size: null,
    price: 14.99,
    unit: "$",
    additionalToppingPrice: null,
    numberOfSlices: 8,
    imageURL: "/images/pizza/sample_pizza.png",
    description:
      "Regular pizza source filled with Bacon, Sausage, Pepporoni, Chicken and flavour of mozzarella cheese",
  },
  {
    pizzaName: "Veggie Crazy",
    size: null,
    price: 10.99,
    unit: "$",
    additionalToppingPrice: null,
    numberOfSlices: 8,
    imageURL: "/images/pizza/sample_pizza.png",
    description:
      "Regular pizza source filled with onion, tomato, black olives, broccoli, green pepper & flavour of mozzarella cheese",
  },
  {
    pizzaName: "Mexican Dorrito",
    size: null,
    price: 10.99,
    unit: "$",
    additionalToppingPrice: null,
    numberOfSlices: 8,
    imageURL: "/images/pizza/pizza.jpg",
    description:
      "Regular pizza source filled with onion, tomato, black olives, broccoli, green pepper & flavour of mozzarella cheese",
  },
  {
    pizzaName: "Pista Pineapple",
    size: null,
    price: 8.75,
    unit: "$",
    additionalToppingPrice: null,
    numberOfSlices: 8,
    imageURL: "",
    description: "",
  },
  {
    pizzaName: "Indian Dream",
    size: null,
    price: 8.75,
    unit: "$",
    additionalToppingPrice: null,
    numberOfSlices: 8,
    imageURL: "",
    description: "",
  },
  {
    pizzaName: "Single Topping",
    size: null,
    price: 8.75,
    unit: "$",
    additionalToppingPrice: null,
    numberOfSlices: 8,
    imageURL: "",
    description: "",
  },
  {
    pizzaName: "Double Topping",
    size: null,
    price: 8.75,
    unit: "$",
    additionalToppingPrice: null,
    numberOfSlices: 8,
    imageURL: "",
    description: "",
  },
];

export const pizza = {
  displayName: "Pizza Mania",
  imageURL: "/images/pizza/pizza.jpg",
  toppings: { displayName: "Toppings", choices: pizzaToppings },
  sizeOptionsAndPrice: pizzaPriceOptions,
};
