import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import Image from "react-bootstrap/Image";

function PizzaItem(props) {
  return (
    <div className="pizza-item menu-item-box-shadow menu-theme-bg-color">
      <Row style={{ marginBottom: "5px" }}>
        <Col className="menu-theme-text">
          <span>{props.pizza.pizzaName}</span>
        </Col>
        <Col className="menu-theme-text">
          {props.pizza.unit}
          {props.pizza.price}
        </Col>
      </Row>
      <Row>
        <Col sm="auto">
          <div style={{ height: "100px", width: "100px" }}>
            <Image fluid={true} src={props.pizza.imageURL} />
          </div>
        </Col>
        <Col>
          <Card.Text className="menu-item-description">
            {props.pizza.description}
          </Card.Text>
        </Col>
      </Row>
    </div>
  );
}

export default PizzaItem;
