import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./menu.css";

function AdditionalMenu(props) {
  return (
    <div className="additional-menu-container">
      <Row className="additional-menu-title">
        <Card.Title>{props.data.title}</Card.Title>
      </Row>
      {props.data.menuItems.map((cc) => {
        return (
          <div className="additional-menu-item menu-item-box-shadow">
            <Row className="align-items-between">
              <Col>
                <Row>
                  <Col>
                    <Card.Subtitle className="menu-theme-text">
                      {cc.itemName}
                    </Card.Subtitle>
                  </Col>
                  <Col>
                    <Card.Text className="menu-theme-text">
                      {cc.unit}
                      {cc.price}
                    </Card.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card.Text className="menu-item-description">
                  {cc.description}
                </Card.Text>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
}

export default AdditionalMenu;
