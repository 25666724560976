import React from "react";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Navbar, Button } from "react-bootstrap";
import "./header.css";

class Header extends React.Component {
  render() {
    return (
      <div className="header-container">
        <Navbar collapseOnSelect expand="lg" bg="light">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse>
            <Nav activeKey="/home">
              <Nav.Item>
                <Nav.Link>
                  {" "}
                  <NavLink to="/home">Home</NavLink>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="menu">
                  {" "}
                  <NavLink to="/menu">Menu</NavLink>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="contact-us">
                  {" "}
                  <NavLink to="/contact-us">Contact</NavLink>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="coupon">
                  <NavLink to="/coupon">Coupons</NavLink>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand>
            <Nav.Link eventKey="logo">
              <NavLink to="/home">
                <div>
                  <img
                    src="/images/icon/store-logo.png"
                    height="75"
                    width="75"
                  ></img>
                </div>
              </NavLink>
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Brand>
            <Button variant="outline-secondary">
              <a href={"tel:" + "641-919-3351"}>Call To Order </a>
            </Button>
          </Navbar.Brand>
        </Navbar>
      </div>
    );
  }
}

export default Header;
