import React from "react";
import { Card } from "react-bootstrap";
import { pizza } from "../../data/pizza_data";
import PizzaItem from "./pizza_item";
import {
  menuHeaderTitle,
  menuHeader2Title,
  menuPlainText,
} from "../../util/html_util";
function Pizza() {
  return (
    <div className="menu-item-container">
      <Card.Title>{pizza.displayName}</Card.Title>
      <div className="pizza-item-container">
        {pizza.sizeOptionsAndPrice.map((item) => {
          return (
            <div className="pizza-item-container-column">
              {" "}
              <PizzaItem pizza={item} />{" "}
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default Pizza;
