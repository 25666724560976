import Card from "react-bootstrap/Card";
import { coupons } from "../../data/store_data";
import "./coupon.css";

function Coupon() {
  return (
    <div className="coupon-container">
      {coupons.map((coupon) => {
        return (
          <Card className="coupon-card text-white">
            <Card.Text>{coupon.mealItemTitle}</Card.Text>
            <Card.Text>{coupon.mealItemDescription}</Card.Text>

            <Card.Text style={{ color: "red" }}>{"$" + coupon.price}</Card.Text>
          </Card>
        );
      })}
    </div>
  );
}

export default Coupon;
