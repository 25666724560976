import React from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { Card } from "react-bootstrap";
import { home } from "../../data/store_data";
import ContactUs from "../contact-us";
import "./home.css";

function Home() {
  return (
    <div className="pizza-home-container">
      <Container fluid>
        <Row>
          <Col>
            <div className="home-image">
              <Image fluid={true} src={home.imageURL} />
            </div>
          </Col>
        </Row>
        <Row className="home-intro">
          <Col>
            <div style={{ display: "flex", minHeight: "100px" }}>
              <Card.Title>{home.titleMessage}</Card.Title>
            </div>
          </Col>
          <Col>
            <Button variant="outline-secondary">
              {" "}
              <NavLink style={{ textDecoration: "none" }} to="/menu">
                Find more on our Menu
              </NavLink>
            </Button>
          </Col>
        </Row>
        <Row>
          <div
            style={{
              display: "flex",
              backgroundColor: "#470006",
              color: "white",
              marginBottom: "10px",
            }}
          >
            <Card.Text>{home.managerMessage}</Card.Text>
          </div>
        </Row>

        <Row>
          <Col>
            <div
              id="contact-us"
              style={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <ContactUs />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
